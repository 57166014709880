import {Component, Input} from "@angular/core";
import {PublishingLandingPage} from "src/app/interfaces/publishing/publishing-landing-page";

@Component({
  selector: "app-publishing-landing-about",
  templateUrl: "./publishing-landing-about.component.html",
  styleUrls: ["./publishing-landing-about.component.scss"],
})
export class PublishingLandingAboutComponent {
  @Input() meta!: PublishingLandingPage;
}
