<div id="publishing-subjects" class="py-5 my-5">
  <div class="container">
    <div class="text-center">
      <h6 class="fw-bold text-primary mt-5">{{ meta.subjectsSectionTitle }}</h6>
      <h6 class="fs-1 fw-bold mt-4 mb-5">{{ meta.subjectsSectionSubtitle }}</h6>
    </div>

    <div class="container-fluid">
      <div class="row justify-content-center g-5">
        <div *ngFor="let subject of subjects" class="col-lg-3 col-md-4 col-sm-8 col-8">
          <div class="card border-0 h-100 shadow-sm rounded-3">
            <div class="card-body p-5">
              <div class="text-light rounded-circle d-flex align-items-center justify-content-center ms-auto">
                <img [src]="subject.image | imageLink" [alt]="subject.title" style="width: 40px; height: 40px" />
              </div>
              <span class="d-block text-center fw-bold text-light-gray fs-6 mt-4">{{ subject.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
