<div id="publishing-about" class="bg-opacity-10 py-5 my-5">
  <div class="container">
    <div class="row">
      <div
        class="col-lg-7 col-12 d-flex d-lg-block flex-column justify-content-center align-items-center text-center text-lg-start"
      >
        <h6 class="fw-bold text-primary mt-5">{{ meta.aboutSectionTitle }}</h6>
        <h6 class="fs-1 fw-bold my-4">{{ meta.aboutSectionSubtitle }}</h6>

        <div class="d-flex align-items-center gap-5 text-start my-4 lh-lg">
          {{ meta.aboutSectionSummary }}
        </div>
      </div>
      <div class="col-lg-5 d-none d-lg-flex p-0 m-0 justify-content-end">
        <img
          [src]="meta.aboutSectionImage | imageLink"
          class="img-fluid cover"
          style="min-height: 300px; max-height: 500px"
          [alt]="meta.aboutSectionTitle"
        />
      </div>
    </div>
  </div>
</div>
