import {Component, Input} from "@angular/core";
import {PublishingLandingPage, PublishingLandingPageRegulation} from "src/app/interfaces/publishing/publishing-landing-page";

@Component({
  selector: "app-publishing-landing-regulations",
  templateUrl: "./publishing-landing-regulations.component.html",
  styleUrls: ["./publishing-landing-regulations.component.scss"],
})
export class PublishingLandingRegulationsComponent {
  @Input() meta!: PublishingLandingPage;
  @Input() regulations!: PublishingLandingPageRegulation[];
}
