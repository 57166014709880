import {Component, Input} from "@angular/core";
import {CarouselSlide} from "src/app/interfaces/carousel-slide";

@Component({
  selector: "app-publishing-landing-carousel",
  templateUrl: "./publishing-landing-carousel.component.html",
  styleUrls: ["./publishing-landing-carousel.component.scss"],
})
export class PublishingLandingCarouselComponent {
  @Input() slides!: CarouselSlide[];
}
