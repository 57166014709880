import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {CarouselSlide} from "src/app/interfaces/carousel-slide";
import {
  PublishingLandingPage,
  PublishingLandingPageData,
  PublishingLandingPageRegulation,
  PublishingLandingPageSubject,
} from "src/app/interfaces/publishing/publishing-landing-page";

@Component({
  selector: "app-publishing-landing-page",
  templateUrl: "./publishing-landing-page.component.html",
  styleUrls: ["./publishing-landing-page.component.scss"],
})
export class PublishingLandingPageComponent implements OnInit {
  meta!: PublishingLandingPage;

  carouselSlides!: CarouselSlide[];
  regulations!: PublishingLandingPageRegulation[];
  subjects!: PublishingLandingPageSubject[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as PublishingLandingPageData;
    this.meta = meta;
    this.carouselSlides = page.data.carousel;
    this.regulations = page.data.regulations;
    this.subjects = page.data.subjects;
  }
}
