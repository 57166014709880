<div id="publishing-regulations" class="py-5 my-5">
  <div class="container">
    <div class="text-center">
      <h6 class="fs-1 fw-bold mt-4 mb-5">{{ meta.regulationsSectionTitle }}</h6>
    </div>

    <div class="d-flex flex-column justify-content-center align-items-center gap-5">
      <ul class="nav nav-pills mx-auto justify-content-center align-items-center" role="tablist">
        <li *ngFor="let regulation of regulations; index as i" class="nav-item mx-2" role="presentation">
          <button
            class="nav-link px-4"
            [ngClass]="{active: i === 0}"
            id="tab{{ i }}-tab"
            data-bs-toggle="tab"
            attr.data-bs-target="#tab{{ i }}-tab-pane"
            type="button"
            role="tab"
            attr.aria-controls="tab{{ i }}-tab-pane"
            aria-selected="false"
          >
            {{ regulation.title }}
          </button>
        </li>
      </ul>

      <div class="tab-content card" style="max-width: 80%">
        <div
          *ngFor="let regulation of regulations; index as i"
          class="tab-pane fade show card-body p-5"
          [ngClass]="{active: i === 0}"
          id="tab{{ i }}-tab-pane"
          attr.role="tabpane{{ i }}"
          attr.aria-labelledby="tab{{ i }}-tab"
          tabindex="0"
        >
          <p [innerHTML]="regulation.content" class="regulation-content"></p>
        </div>
        <div
          *ngFor="let regulation of regulations; index as i"
          class="tab-pane fade"
          id="tab{{ i + 1 }}-tab-pane"
          role="tabpanel"
          attr.aria-labelledby="tab{{ i + 1 }}-tab"
          tabindex="0"
        >
          ...
        </div>
      </div>
    </div>
  </div>
</div>
