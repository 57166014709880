import {Component, Input} from "@angular/core";
import {PublishingLandingPage, PublishingLandingPageSubject} from "src/app/interfaces/publishing/publishing-landing-page";

@Component({
  selector: "app-publishing-landing-subjects",
  templateUrl: "./publishing-landing-subjects.component.html",
  styleUrls: ["./publishing-landing-subjects.component.scss"],
})
export class PublishingLandingSubjectsComponent {
  @Input() meta!: PublishingLandingPage;
  @Input() subjects!: PublishingLandingPageSubject[];
}
